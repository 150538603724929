import * as userAgentHelper from 'userAgentHelper';
import sha256 from './sha256';

export default async function identifyUser(user) {
    if (!user) return;

    // these are standardized in segment. see also - https://segment.com/docs/spec/identify/#traits
    const payload = {
        cordova: !!window.CORDOVA,
        email: user.email,
        emailDomain: user.emailDomain,
        name: user.name,
        plan_id: user.plan_id,
        sign_up_code: user.sign_up_code,
        birthday: user.birthdate,
        phone: user.phone,
        address: {
            city: user.city,
            postalCode: user.zip,
            state: user.state,
        },
    };

    // Only Iguana objects have these functions
    try {
        payload.groups = user.userGroupNames().join(',');
        payload.institutions = user.institutionNames().join(',');
        payload.globalRole = user.roleName();
    } catch (e) {
        // Do nothing
    }

    // Until we get a proper acknowledge page we need to stop sending this information to
    // analytics on Android because it is in violation of Play store terms.
    if (window.CORDOVA && userAgentHelper.isAndroidDevice()) {
        delete payload.email;
        delete payload.emailDomain;
        delete payload.name;
        delete payload.birthday;
        delete payload.phone;
        delete payload.address;
    }

    window.analytics?.identify(user.id, payload);

    // Push attributes to the GTM data layer
    const sha256Values = await Promise.all([sha256(user.email), sha256(user.phone)]);
    window.dataLayer?.push({
        sha256_email_address: sha256Values[0],
        sha256_phone_number: sha256Values[1],
    });
}
