import {
    type UnknownAction,
    type configureStore,
    combineReducers,
    type Slice,
    type ReducersMapObject,
    type Middleware,
    type Reducer,
} from '@reduxjs/toolkit';
import { type LooksLikeAnApi } from './ReduxHelpers.types';

const noOpReducer: Reducer = (state, _action: UnknownAction) => state;

export function combineApisAndSlices({
    apis,
    slices,
    middleware,
}: {
    apis?: LooksLikeAnApi[];
    slices?: Slice[];
    middleware?: Middleware[];
}) {
    apis = apis || [];
    slices = slices || [];

    const apiReducers: ReducersMapObject = apis.reduce((acc, api) => {
        acc[api.reducerPath] = api.reducer;
        return acc;
    }, {} as ReducersMapObject);

    const reducers = slices.reduce((acc, slice) => {
        acc[slice.name] = slice.reducer;
        return acc;
    }, apiReducers);

    const combinedMiddleware = apis.reduce((acc, api) => {
        acc.push(api.middleware);
        return acc;
    }, middleware || []);

    return {
        reducer: Object.keys(reducers).length > 0 ? combineReducers(reducers) : noOpReducer,
        middleware: getDefault =>
            getDefault({
                // serializableCheck is a built-in check that runs only in dev mode to warn you if you
                // put non-serializable objects into Redux. The tool has a built-in warning when it is running
                // slowly, and we started seeing that warning regularly and having the dev app run really slowly. The
                // warning says that if you're putting big objects into redux, then the check might be too slow and
                // you might have to disable it. We are intentionally putting big objects in Redux, so it seems like
                // we're just gonna have to live without this check.
                serializableCheck: false,
            }).concat(combinedMiddleware),
    } as Parameters<typeof configureStore>[0];
}
