import { createApi as rtkCreateApi } from '@reduxjs/toolkit/query/react';
import { type AnyFunction } from '@Types';
import { type EndpointBuilder, type EndpointDefinitions } from '@reduxjs/toolkit/query';
import { type ApiWithHooks } from './ReduxHelpers.types';
import { createMakeRequestFunction } from './createMakeRequestFunction';

export const createApi = <
    BaseQuery extends AnyFunction,
    Path extends string,
    Definitions extends EndpointDefinitions,
    TagTypes extends string = never,
>(
    reducerPath: Path,

    // We don't really want to exclude extractRehydrationInfo, but there's some mysterious interaction
    // between it and reducerPath that makes it hard to placate typescript if we don't omit it
    config: {
        baseQuery: BaseQuery;
        tagTypes?: TagTypes[];
        endpoints: (build: EndpointBuilder<BaseQuery, TagTypes, Path>) => Definitions;
    } & Omit<Parameters<typeof rtkCreateApi>[0], 'endpoints' | 'extractRehydrationInfo' | 'tagTypes'>,
) => {
    const api = rtkCreateApi({
        ...config,
        reducerPath,
    });

    const makeRequest = createMakeRequestFunction(
        api as unknown as ApiWithHooks<BaseQuery, Definitions, Path, TagTypes>,
    );

    return { ...api, makeRequest };
};

export default createApi;
