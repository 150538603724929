import { type LinkToLessonInStream } from 'Lessons';
import clsx from 'clsx';
import SourceLink from './SourceLink';
import StreamLink from './StreamLink';

type Props = {
    lessonLink: LinkToLessonInStream;
    isFirstInStream: boolean;
    isFirstRow: boolean;
};

export default function LessonLinkRow({ lessonLink, isFirstInStream, isFirstRow }: Props) {
    return (
        <>
            {isFirstInStream && !isFirstRow && (
                <>
                    <div className={clsx('col-[left-col]', '[&:(:first-of-type)]:hidden:-mt-2')} />
                    <div className={clsx('col-[right-col]', '[&:(:first-of-type)]:hidden:-mt-2')} />
                </>
            )}
            {/* For the first lesson in each stream we include a link to the stream in the first cell */}
            {isFirstInStream && (
                <div className={clsx('col-[left-col] uppercase', 'max-sm:[&:not(:first-of-type)]:mt-4')}>
                    <StreamLink lessonLink={lessonLink} />
                </div>
            )}
            <SourceLink url={lessonLink.lessonLaunchPath} title={lessonLink.lessonTitle} className="uppercase" />
        </>
    );
}
