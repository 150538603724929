import { type AnyObject } from '@Types';
import { type Brand } from 'AppBranding';
import { type CareerProfileIguanaObject } from 'CareerProfiles';
import { type ProgramType } from 'Program';

export type StudentNetworkEvent = {
    recommended: boolean;
    start_time: number;
    title: string;
    id: string;
    anonymized: boolean;
};

export type StudentNetworkInstance = {
    index: (config?: {
        fields?: string[];
        sort?: string;
        filters?: AnyObject;
        limit?: number;
        offset?: number;
        direction?: 'asc' | 'desc';
    }) => Promise<{ result: StudentNetworkEvent[] }>;
};

export enum StudentNetworkQuickFilterKey {
    allMba = 'all_mba',
    allEmba = 'all_emba',
    allExecutiveEducation = 'all_executive_education',
    allMsse = 'all_msse',
    allMsba = 'all_msba',
}

export type StudentNetworkQuickFilterConfig = {
    key: ProgramType[];
    name: StudentNetworkQuickFilterKey;
    label: { localeKey: string; params?: AnyObject };
};

export type CandidateListCardViewHelper = {
    getSummarizedProfileInfo: (careerProfile: CareerProfileIguanaObject) => SummarizedProfileInfo;
};

export type SummarizedProfileInfo = {
    location: string;
    messagingLocation: string;
    utcOffset: number;
    timezone: string;
    programs: Partial<
        Record<
            Brand,
            {
                logo: SummarizedProgramLogo;
                programs: SummarizedProgram[];
            }
        >
    >;
    userId: string;
};
export type SummarizedProgramLogo = { image: string; imageOnly: string; alt: string };
export type SummarizedProgram = { title: string; startDate: number; isDegreeProgram: boolean };
