import { cva, type VariantProps } from 'class-variance-authority';
import { Slot } from '@radix-ui/react-slot';
import { type ReactNode } from 'react';

const typographyStyles = cva(null, {
    variants: {
        variant: {
            header1:
                'text-2xl font-medium leading-[34px] tracking-[0.24px] md:text-3xl md:leading-10 md:tracking-[0.3px]',
            header2: 'text-md font-medium leading-7 md:text-xl md:leading-7.5',
            header3: 'text-md font-bold leading-7 md:text-lg',
            body1: 'text-md leading-7',
            body2: 'text-base leading-7.5',
            body3: 'text-xs leading-7 md:text-sm md:leading-[17px]',
            body4: 'text-sm leading-5',
            smallText: 'text-xs leading-5.5',
            link: 'text-xs leading-[17px]',
        },
    },
    defaultVariants: {
        variant: 'body1',
    },
});

function getDefaultTag(variant?: TypographyProps['variant']) {
    switch (variant) {
        case 'header1':
            return 'h1';
        case 'header2':
            return 'h2';
        case 'header3':
            return 'h3';
        case 'body1':
        case 'body2':
        case 'body3':
        case 'body4':
        case 'smallText':
            return 'p';
        case 'link':
            return 'span';
        default:
            return 'p';
    }
}

type TypographyProps = { children?: ReactNode; className?: string; asChild?: boolean } & VariantProps<
    typeof typographyStyles
>;

export function Typography({ variant, className, asChild, ...props }: TypographyProps) {
    const Comp = asChild ? Slot : getDefaultTag(variant);
    return <Comp className={typographyStyles({ variant, className })} {...props} />;
}
