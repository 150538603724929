import { type BrandConfig } from 'AppBranding';
import {
    type ValarAchievementDesign,
    type ProgramAchievementGraphicDesign,
} from 'ShareableGraphics/ShareableGraphics.types';
import { type CurrentUserIguanaObject } from 'Users';
import { SVG_VIEWBOX_HEIGHT, SVG_VIEWBOX_WIDTH } from 'ShareableGraphics/constants';
import { useTranslation } from 'react-i18next';
import { getWrappedTextLines } from 'ShareableGraphics/helpers/helpers';
import { formattedUserFacingYear } from 'DateHelpers';

export function AcceptedGraphicValar({
    selectedDesign,
    currentUser,
    brandConfig,
}: {
    selectedDesign: ProgramAchievementGraphicDesign;
    currentUser: CurrentUserIguanaObject;
    brandConfig: BrandConfig;
}) {
    const { t } = useTranslation('back_royal');
    const design = selectedDesign as ValarAchievementDesign;
    const wrappedName = getWrappedTextLines(currentUser.name, 30);
    const programTitleLines = currentUser.relevantCohort!.programAchievementGraphicProgramTitleLines!;
    const graphicConfig = brandConfig.programAchievementGraphics!.accepted;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${SVG_VIEWBOX_WIDTH} ${SVG_VIEWBOX_HEIGHT}`}
            className="block"
        >
            <g id="EMBA_Blue">
                <rect width="600" height="928" fill="#fff" strokeWidth="0" />
                <g>
                    <g>
                        <g>
                            <circle cx="299.83" cy="373.35" r="110.05" fill={design.primaryColor.hex} strokeWidth="0" />
                            <path
                                d="M253.55,326.16h23.97l22.95,53.97h.23l22.83-53.97h23.97l-35.79,79.53h-22.38l-35.78-79.53ZM299.83,417.87h0c7.08,0,12.82,5.74,12.82,12.82h0c0,7.08-5.74,12.82-12.82,12.82h0c-7.08,0-12.82-5.74-12.82-12.82h0c0-7.08,5.74-12.82,12.82-12.82Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                        </g>
                        <path
                            d="M248.26,523.73h5.63l5.42,12.71h.05l5.38-12.72h5.63l-8.42,18.75h-5.27l-8.43-18.74ZM283.77,539.77h-8.67l-1.1,2.7h-5.3l8.29-18.74h4.9l8.29,18.74h-5.32l-1.1-2.7ZM282,535.35l-2.54-6.32h-.06l-2.54,6.32h5.14ZM292.9,523.73h5.28v14.13h9.39v4.61h-14.67v-18.74ZM324.67,539.77h-8.69l-1.1,2.7h-5.3l8.29-18.74h4.9l8.29,18.74h-5.32l-1.08-2.7ZM322.9,535.35l-2.55-6.32h-.06l-2.54,6.32h5.14ZM351.41,542.47h-5.75l-3.64-5.78h-2.94v5.78h-5.28v-18.74h10.2c4.26,0,7.25,2.36,7.25,6.45.09,2.64-1.55,5.04-4.04,5.91v.08l4.2,6.29ZM343.64,528.15h-4.58v4.12h4.55c1.47,0,2.35-.73,2.35-2.09s-.83-2.03-2.33-2.03Z"
                            fill="#000"
                            strokeWidth="0"
                        />
                        <g>
                            <path d="M231.88,555.71h2.97v13.59h-2.97v-13.59Z" fill="#000" strokeWidth="0" />
                            <path
                                d="M241.42,555.71h3.24l6.37,9.41h.04v-9.41h2.97v13.59h-3.22l-6.38-9.43h-.04v9.43h-2.97v-13.59Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                            <path
                                d="M262.53,564.23c.41,1.96,1.55,2.85,3.67,2.85,1.73,0,2.72-.54,2.72-1.53,0-.93-.74-1.42-3.75-2.08-3.73-.83-5.22-2.04-5.22-4.08,0-2.21,2.17-3.96,5.38-3.96,1.55,0,2.87.37,3.98,1.09,1.11.74,1.8,1.73,2.12,2.99l-2.87.68c-.45-1.48-1.55-2.23-3.24-2.23-1.4,0-2.39.58-2.39,1.42,0,.7.64,1.05,3.67,1.71,3.65.8,5.3,2.13,5.3,4.44,0,2.46-2.23,4.06-5.71,4.06-3.65,0-6.04-1.73-6.56-4.77l2.91-.58Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                            <path
                                d="M280.87,558.33h-4.83v-2.62h12.63v2.62h-4.83v10.96h-2.97v-10.96Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                            <path d="M294.38,555.71h2.97v13.59h-2.97v-13.59Z" fill="#000" strokeWidth="0" />
                            <path
                                d="M307.88,558.33h-4.83v-2.62h12.63v2.62h-4.83v10.96h-2.97v-10.96Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                            <path
                                d="M321.29,555.71h2.97v8.23c0,2.1,1.07,3.05,3.18,3.05s3.18-.95,3.18-3.03v-8.25h2.97v8.36c0,3.63-2.21,5.51-6.15,5.51s-6.15-1.88-6.15-5.51v-8.36Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                            <path
                                d="M344.01,558.33h-4.83v-2.62h12.63v2.62h-4.83v10.96h-2.97v-10.96Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                            <path
                                d="M357.52,555.71h10.27v2.6h-7.3v2.85h6.6v2.46h-6.6v3.07h7.28v2.6h-10.25v-13.59Z"
                                fill="#000"
                                strokeWidth="0"
                            />
                        </g>
                    </g>
                    <polygon points="169 928 0 928 0 555 169 928" fill={design.primaryColor.hex} strokeWidth="0" />
                    <polygon points="431 928 600 928 600 555 431 928" fill={design.primaryColor.hex} strokeWidth="0" />
                </g>
                <g id="_Text_" data-name="&amp;lt;Text&amp;gt;">
                    <text fill="#000" fontFamily="Montserrat Medium" fontSize="22">
                        <tspan
                            textAnchor="middle"
                            x="50%"
                            y="833"
                            fontSize={graphicConfig.institutionNameLines[0].fontSize}
                        >
                            {graphicConfig.institutionNameLines[0].text}
                        </tspan>
                    </text>

                    {/* Program Title */}
                    {programTitleLines.length === 1 && (
                        <text fill={design.primaryColor.hex} fontFamily="Montserrat Bold" fontSize="44">
                            <tspan textAnchor="middle" x="50%" y="695">
                                {programTitleLines[0]}
                            </tspan>
                        </text>
                    )}

                    {programTitleLines.length === 2 && (
                        <text fill={design.primaryColor.hex} fontFamily="Montserrat Bold" fontSize="38">
                            <tspan textAnchor="middle" x="50%" y="695">
                                {programTitleLines[0]}
                            </tspan>
                            <tspan textAnchor="middle" x="50%" y="735">
                                {programTitleLines[1]}
                            </tspan>
                        </text>
                    )}

                    <text fill="#000" fontFamily="Montserrat Medium" fontSize="26">
                        <tspan textAnchor="middle" x="50%" y="634">
                            {t(
                                'program_achievement_graphic.program_achievement_graphic.you_have_been_accepted_into_the',
                            )}
                        </tspan>
                    </text>
                    <text fill={design.primaryColor.hex} fontFamily="Montserrat Bold" fontSize="53">
                        <tspan textAnchor="middle" x="50%" y="228">
                            {t('program_achievement_graphic.program_achievement_graphic.class_of')?.toUpperCase()}
                            &nbsp;
                            {formattedUserFacingYear(currentUser.relevantCohort!.graduationDate, false)}
                        </tspan>
                    </text>
                    <text fill="#000" fontFamily="Montserrat SemiBold" fontSize="24">
                        <tspan textAnchor="middle" x="50%" y="113">
                            {wrappedName[0]?.toUpperCase()}
                        </tspan>
                        <tspan textAnchor="middle" x="50%" y="144">
                            {wrappedName[1]?.toUpperCase()}
                        </tspan>
                    </text>
                    <text fill={design.primaryColor.hex} fontFamily="Montserrat Medium" fontSize="22">
                        <tspan textAnchor="middle" x="50%" y="79">
                            {t('program_achievement_graphic.program_achievement_graphic.congratulations')}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
