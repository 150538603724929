import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import SendbirdSdkProvider from './Provider';

export const initializeSendbirdSdk = (appId: string) => {
    if (SendbirdSdkProvider.sdk?.appId === appId) return;

    const sendbirdSdk = SendbirdChat.init({
        appId,
        modules: [new GroupChannelModule()],
    });

    SendbirdSdkProvider.sdk = sendbirdSdk;
};

export { SendbirdSdkProvider };
