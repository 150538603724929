import { useMemo } from 'react';
import { useSyncConfig } from 'FrontRoyalConfig';
import { regionAwareImageUrlForFormat } from 'regionAwareImage';
import { type LinkToLessonInStream } from 'Lessons';
import clsx from 'clsx';
import { linkStyles } from './shared';

type Props = { lessonLink: LinkToLessonInStream };

export default function StreamLink({ lessonLink }: Props) {
    const config = useSyncConfig();
    const streamImage = useMemo(() => {
        if (!lessonLink.streamImage) return null;

        return regionAwareImageUrlForFormat(lessonLink.streamImage, '50X50', config);
    }, [lessonLink, config]);

    return (
        <div className="relative flex items-start">
            {streamImage && (
                <img
                    className="absolute -top-1 left-0 max-w-[25px] max-xs:top-0 rtl:left-auto rtl:right-0"
                    src={streamImage}
                    alt={lessonLink.streamTitle}
                />
            )}
            <a
                href={lessonLink.streamDashboardPath}
                data-testid="stream-link"
                target="_blank"
                rel="noreferrer"
                className={clsx(linkStyles, streamImage && 'ps-8')}
            >
                {lessonLink.streamTitle}
            </a>
        </div>
    );
}
