import {
    HelpScoutCollectionId,
    type DisplayableNonLessonFrameSourceLocation,
    type HelpscoutArticleSourceLocation,
} from 'TutorBot';
import { type LinkToLessonInStream } from 'Lessons';
import { isHelpscoutSourceLocation } from 'TutorBot/utils/sourceLocationHelpers';

export const helpScoutCollectionTitles = {
    'Student Handbook': [HelpScoutCollectionId.QuanticStudentHandbook, HelpScoutCollectionId.ValarStudentHandbook],
    'Student Resources': [HelpScoutCollectionId.QuanticStudentResources, HelpScoutCollectionId.ValarStudentResources],
    General: [HelpScoutCollectionId.QuanticGeneral, HelpScoutCollectionId.ValarGeneral],
    Applicants: [HelpScoutCollectionId.QuanticApplicants, HelpScoutCollectionId.ValarApplicants],
};

export function helpScoutCollectionTitle(collectionId: HelpScoutCollectionId) {
    let title = '';

    Object.entries(helpScoutCollectionTitles).forEach(([helpscoutCollectionTitle, includedCollectionIds]) => {
        if (includedCollectionIds.includes(collectionId)) {
            title = helpscoutCollectionTitle;
        }
    });

    return title;
}

export function isFirstLinkFromHelpScoutCollection(
    nonLessonLink: DisplayableNonLessonFrameSourceLocation,
    nonLessonLinks: DisplayableNonLessonFrameSourceLocation[],
) {
    if (!isHelpscoutSourceLocation(nonLessonLink)) return false;
    const helpscoutLink = nonLessonLink as HelpscoutArticleSourceLocation;
    const helpscoutLocations: HelpscoutArticleSourceLocation[] = nonLessonLinks.filter(l =>
        isHelpscoutSourceLocation(l),
    ) as HelpscoutArticleSourceLocation[];
    return helpscoutLocations.filter(l => l.collectionId === helpscoutLink.collectionId).indexOf(helpscoutLink) === 0;
}

export function isFirstLessonLinkInStream(lessonLink: LinkToLessonInStream, lessonLinks: LinkToLessonInStream[]) {
    return lessonLinks.filter(l => l.streamId === lessonLink.streamId).indexOf(lessonLink) === 0;
}

export const linkStyles =
    'transition focus-visible:outline focus-visible:outline-1 quantic:text-coral quantic:hover:text-coral-hover valar:text-blue valar:hover:text-blue-dark';
