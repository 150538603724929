import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faMemoCircleInfo } from '@fortawesome/pro-light-svg-icons/faMemoCircleInfo';
import clsx from 'clsx';
import { linkStyles } from './shared';

type Props = {
    url: string;
    title: string;
    fullWidth?: boolean;
    className?: string;
};

export default function SourceLink({ url, title, fullWidth = false, className = '' }: Props) {
    return (
        <a
            data-testid="lesson-link"
            href={url}
            target="_blank"
            rel="noreferrer"
            className={clsx(
                linkStyles,
                'flex items-start gap-[5px]',
                fullWidth && 'col-span-2',
                !fullWidth && 'col-[right-col] max-sm:pl-2',
                className,
            )}
        >
            {/* When a link is not "fullWidth", then it is part of a section. The arrow
            connects it with the section, which has it's own link on the left. */}
            {!fullWidth && (
                <FontAwesomeIcon
                    className="inline-block grow-0 text-xs text-tutorbot-chat max-sm:pe-2 sm:px-2 rtl:-scale-x-100"
                    icon={faLongArrowRight}
                />
            )}

            {/* When the link is full width, it is not part of a section, so we replace the
            arrow with a generic faMemoCircleInfo */}
            {fullWidth && (
                <FontAwesomeIcon
                    className="inline-block grow-0 px-1 pl-[2px] text-md text-tutorbot-chat"
                    icon={faMemoCircleInfo}
                />
            )}
            {title}
        </a>
    );
}
