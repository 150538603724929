import { useCallback, useMemo } from 'react';
import { faThumbsDown } from '@fortawesome/pro-regular-svg-icons/faThumbsDown';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { type StudentMessageEvaluation } from '../TutorBot.types';
import { chatActions, getStudentEvaluationForMessageSelector } from '../redux/chat';
import { EVENT_STUDENT_EVALUATED_MESSAGE } from '../constants';

type Props = {
    messageId: string;
};

type StudentEvaluatedEventPayload = {
    tutor_bot_message_id: StudentMessageEvaluation['messageId'];
    label: StudentMessageEvaluation['label'];
};

export function MessageEvaluations({ messageId }: Props) {
    const classes = [' hover:text-tutorbot-chat transition hover:cursor-pointer'];
    const { t } = useTranslation('back_royal');
    const dispatch = useDispatch();
    const EventLogger = useEventLogger();

    const studentEvaluationSelector = useMemo(() => getStudentEvaluationForMessageSelector(messageId), [messageId]);
    const studentEvaluation = useSelector(studentEvaluationSelector);

    const logStudentEvaluation = useCallback(
        (evaluation: StudentMessageEvaluation) => {
            const eventPayload = {
                tutor_bot_message_id: evaluation.messageId,
                label: evaluation.label,
            } as StudentEvaluatedEventPayload;

            EventLogger.log(EVENT_STUDENT_EVALUATED_MESSAGE, eventPayload);
        },
        [EventLogger],
    );

    const handleThumbClick = useCallback(
        (label: StudentMessageEvaluation['label']) => {
            const evaluation = { messageId, label, createdAt: new Date().getTime() };
            dispatch(chatActions.setStudentEvaluation({ messageId, evaluation }));
            logStudentEvaluation(evaluation);
        },
        [dispatch, messageId, logStudentEvaluation],
    );

    return (
        // relative is necessary here so that this component will be the offset parent of the sr-only
        // element. If we don't do that, then the thumbs buttons can change the size of something way up in the
        // dom, leading to unexpeted scrollbar behavior.
        <span className="relative ml-2.5 mt-2.5 flex gap-2 self-start rtl:ml-0 rtl:mr-2.5">
            <button type="button" data-testid="thumbs-up-button" onClick={() => handleThumbClick('thumbs_up')}>
                <FontAwesomeIcon
                    icon={faThumbsUp}
                    className={clsx(
                        classes,
                        studentEvaluation?.label === 'thumbs_up'
                            ? 'quantic:text-coral valar:text-blue'
                            : 'text-tutorbot-evaluation-icon',
                    )}
                />
                <span className="sr-only">{t('chat.chat.rate_thumbs_up')!}</span>
            </button>
            <button type="button" data-testid="thumbs-down-button" onClick={() => handleThumbClick('thumbs_down')}>
                <FontAwesomeIcon
                    icon={faThumbsDown}
                    className={clsx(
                        classes,
                        'mt-0.5',
                        studentEvaluation?.label === 'thumbs_down'
                            ? 'quantic:text-coral valar:text-blue'
                            : 'text-tutorbot-evaluation-icon',
                    )}
                />
                <span className="sr-only">{t('chat.chat.rate_thumbs_down')!}</span>
            </button>
        </span>
    );
}
