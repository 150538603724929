import { useMemo } from 'react';
import { type HelpscoutArticleSourceLocation, HelpScoutCollectionId, HelpScoutCollectionUrl } from 'TutorBot';
import { fetchBrandConfig, Brand } from 'AppBranding';
import clsx from 'clsx';
import { linkStyles } from './shared';
import { helpScoutCollectionTitle } from '../../utils/helpScoutCollectionTitle';

export default function HelpScoutCollectionLink({ link }: { link: HelpscoutArticleSourceLocation }) {
    // We can safely typecast `nonLessonLink.collectionId` to `HelpScoutCollectionIds` since we've already checked
    // that this collectionId is included in the enum via the `isFromHelpScout()` call in the `<NonLessonLinkRow />` component.
    const collectionId = link.collectionId;

    const isQuanticLink = useMemo(
        () =>
            [
                HelpScoutCollectionId.QuanticStudentHandbook,
                HelpScoutCollectionId.QuanticApplicants,
                HelpScoutCollectionId.QuanticGeneral,
                HelpScoutCollectionId.QuanticStudentResources,
                HelpScoutCollectionId.ExecEdStudentHandbook,
                HelpScoutCollectionId.ExecEdApplicants,
                HelpScoutCollectionId.ExecEdStudentResources,
                HelpScoutCollectionId.ExecEdGeneral,
            ].includes(collectionId),
        [collectionId],
    );
    const brandConfig = useMemo(() => fetchBrandConfig(isQuanticLink ? Brand.quantic : Brand.valar), [isQuanticLink]);

    const collectionTitle = useMemo(() => helpScoutCollectionTitle(collectionId), [collectionId]);

    const linkToCollection = useMemo(() => {
        const collectionName = Object.keys(HelpScoutCollectionId)[
            Object.values(HelpScoutCollectionId).indexOf(collectionId)
        ] as keyof typeof HelpScoutCollectionUrl;
        return HelpScoutCollectionUrl[collectionName];
    }, [collectionId]);

    return (
        <div className="relative flex items-start">
            <img
                className="absolute left-[2px] -mt-1 max-h-[25px] rtl:left-auto rtl:right-0"
                src={brandConfig.appMenuProgramLogo as string}
                alt={collectionTitle}
            />
            <a
                href={linkToCollection}
                data-testid="stream-link"
                target="_blank"
                rel="noreferrer"
                className={clsx(linkStyles, 'ps-8')}
            >
                {collectionTitle}
            </a>
        </div>
    );
}
