import { type BrandConfig } from 'AppBranding';
import { formattedUserFacingMonthYearLong } from 'DateHelpers';
import { useTranslation } from 'react-i18next';
import { SVG_VIEWBOX_HEIGHT, SVG_VIEWBOX_WIDTH } from 'ShareableGraphics/constants';
import { getWrappedTextLines } from 'ShareableGraphics/helpers/helpers';
import {
    type QuanticAchievementDesign,
    type ProgramAchievementGraphicDesign,
} from 'ShareableGraphics/ShareableGraphics.types';
import { type CurrentUserIguanaObject } from 'Users';
import { CrosshatchBackgroundGroup } from './shared/CrosshatchBackgroundGroup';

export function GraduatedGraphicQuantic({
    selectedDesign,
    brandConfig,
    currentUser,
}: {
    selectedDesign: ProgramAchievementGraphicDesign;
    brandConfig: BrandConfig;
    currentUser: CurrentUserIguanaObject;
}) {
    const { t } = useTranslation('back_royal');
    const design = selectedDesign as QuanticAchievementDesign;
    const wrappedName = getWrappedTextLines(currentUser.name, 30);
    const withHonors = currentUser.graduationStatus === 'honors';
    const programTitleLines = currentUser.relevantCohort!.programAchievementGraphicProgramTitleLines!;
    const graphicConfig = brandConfig.programAchievementGraphics!.graduated;
    const isExecEd = currentUser.relevantCohort!.isExecEd;
    const graduatedLocaleKey = isExecEd ? 'completed' : 'graduated';
    const awardedLocaleKey = isExecEd ? 'exec_ed_cert' : 'awarded_degree';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox={`0 0 ${SVG_VIEWBOX_WIDTH} ${SVG_VIEWBOX_HEIGHT}`}
            className="block"
        >
            <defs>
                <linearGradient id="linear-gradient" x1="50%" y1="0%" x2="50%" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor={design.gradient.stop1Color} />
                    <stop offset="52%" stopColor={design.gradient.stop2Color} />
                    <stop offset="100%" stopColor={design.gradient.stop3Color} />
                </linearGradient>
                <clipPath id="clippath-quantic-graduated">
                    <rect width="600" height="928" fill="none" strokeWidth="0" />
                </clipPath>
            </defs>
            <g id="EMBA_Blue">
                <g>
                    <rect x="0%" y="0%" width="600" height="928" fill="url(#linear-gradient)" strokeWidth="0" />
                    <g clipPath="url(#clippath-quantic-graduated)">
                        <g
                            id="Crosshatch_Layer"
                            data-name="Crosshatch Layer"
                            opacity=".18"
                            style={{ transform: 'translateX(2px)' }}
                        >
                            <CrosshatchBackgroundGroup />
                        </g>
                    </g>
                    <rect x="1.01" y="777.68" width="600" height="151" fill="#000" opacity=".2" strokeWidth="0" />
                    <g>
                        <g>
                            <path
                                d="M274.76,560.3c-7.7,0-14,2.3-19.7,7.5,5.6,3.6,11.3,5.2,17.9,5.2,8.1,0,15.4-2.3,22.4-7.3-9.4-4.5-14.7-5.4-20.6-5.4h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M290.96,561c-2.3-6.9-5.4-12.1-9.4-16.1-3.5-3.5-10.9-9.1-22.4-8.8,1.2,6.4,3.6,11,7.7,15.2,6.4,6.3,16.3,8.8,24.1,9.7h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M249.46,552.3c-7.4,0-13.8,1.7-20.5,5.6,6.3,4.2,12.5,6,19.9,6,8.9,0,15-2.6,20.4-6.4-6.1-3.3-12.2-5.2-19.8-5.2Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M257.86,552.9c.1-13-2.9-19.3-7.6-24.3-3.1-3.3-7-6.1-15.3-9.4-.9,9.1,2.3,18.1,9.3,25.1,4,4,7.9,6.5,13.6,8.6h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M221.06,538c-6.6,0-11.4,1.5-16.3,4.5,4.7,5.7,11.1,10.1,21.1,10.1,6.4,0,12.8-1.5,18.9-4.5-5.9-5-13.6-10.1-23.7-10.1h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M234.96,540.5c-.4-10.5-4.5-21-11.2-27.6-3.5-3.4-7.9-7-18.3-10.2-.4,5.6.4,15.4,9.5,24.4,6,6.1,12.2,9.8,20,13.4h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M219.16,535.7c-2.9-5.7-7.3-12-14-16.2-9.4-5.9-17.8-6.5-25.1-5.4,3.2,6.9,6.6,11.8,12.1,16.1,7.2,5.8,17.2,6.5,27,5.5h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M199.76,513.6c-.4-7.2-2.1-15.8-6.7-22-4.5-6.1-10.2-9.9-19.1-12.3-.1,9.4,3,17.2,9.2,23.4,4.2,4.1,9.8,7.8,16.6,10.9h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M195.76,472.2c0-8.8-3.9-16.4-11.4-22.3-2.2,4.1-3.3,8.3-3.3,12.9,0,9.9,5.4,18.4,11.3,25.3,2.6-6.5,3.4-10.4,3.4-15.9h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M306.76,565.6c7.1,4.9,14.3,7.3,22.4,7.3,6.6,0,12.3-1.6,17.9-5.2-5.7-5.2-11.9-7.5-19.7-7.5-5.9.1-11.2,1-20.6,5.4h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M342.96,536.1c-11.5-.3-18.9,5.3-22.4,8.8-4,4-7.1,9.2-9.4,16.1,7.9-.9,17.8-3.4,24.1-9.7,4.1-4.1,6.5-8.8,7.7-15.2Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M332.76,557.5c5.5,3.8,11.6,6.4,20.4,6.4,7.4,0,13.6-1.8,19.9-6-6.7-3.9-13-5.6-20.5-5.6-7.3,0-13.6,1.9-19.8,5.2Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M367.06,519.3c-8.3,3.3-12.1,6.1-15.3,9.4-4.7,5-7.7,11.3-7.6,24.3,5.7-2.1,9.6-4.6,13.6-8.6,7.1-7,10.3-16,9.3-25.1h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M357.46,548.1c6.1,3,12.4,4.5,18.9,4.5,10,0,16.4-4.5,21.1-10.1-4.9-3-9.7-4.5-16.3-4.5-10.2-.1-18,5.1-23.7,10.1h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M387.16,527.1c9.1-9.1,10-18.8,9.5-24.4-10.4,3.2-14.8,6.7-18.3,10.2-6.8,6.6-10.9,17-11.2,27.6,7.8-3.6,14-7.3,20-13.4h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M396.96,519.6c-6.7,4.2-11.1,10.5-14,16.2,9.7.9,19.7.3,27.1-5.5,5.5-4.3,8.8-9.2,12.1-16.1-7.4-1.2-15.8-.5-25.2,5.4h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M428.06,479.3c-8.9,2.5-14.5,6.2-19.1,12.3-4.6,6.1-6.3,14.8-6.7,22,6.8-3.2,12.5-6.9,16.5-11,6.3-6.1,9.3-13.9,9.3-23.3h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M421.06,462.8c0-4.6-1.1-8.8-3.3-12.9-7.5,5.9-11.4,13.5-11.4,22.3,0,5.5.7,9.5,3.4,15.9,5.9-6.9,11.3-15.4,11.3-25.3h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                            <path
                                d="M306.06,575.3c-.8-2.5-1.1-4.9-1.4-6.8-.3-1.8-.5-3-.9-3.9-.5-1.1-1.3-1.6-2.7-1.6s-2.2.5-2.7,1.6c-.4.8-.6,2.1-.9,3.9-.3,1.9-.6,4.2-1.4,6.8-.9,3.1-2.3,5.8-3.4,7.9-1.1,2.2-1.9,3.7-1.9,4.9,0,1.6,1.1,2.8,2.7,2.8,2.6,0,3.5-2.8,4.7-6.3l.5-1.5.4-1.2c.6-1.9,1.1-3.5,2-3.5s1.4,1.6,2,3.5l.4,1.2.5,1.5c1.2,3.5,2.1,6.3,4.7,6.3,1.6,0,2.7-1.1,2.7-2.8,0-1.2-.8-2.8-1.9-4.9-1.1-2.1-2.5-4.8-3.4-7.9h0Z"
                                fill="#fff"
                                strokeWidth="0"
                            />
                        </g>
                        <polygon
                            points="217.56 396.4 217.56 492.9 301.06 541.1 384.56 492.9 384.56 396.4 301.06 348.2 217.56 396.4"
                            fill="#fff"
                            fillRule="evenodd"
                            strokeWidth="0"
                        />
                        <g>
                            <polygon
                                points="301.06 444.6 301.06 471.9 348.26 499.1 348.26 471.9 301.06 444.6"
                                fill={design.logoBg}
                                strokeWidth="0"
                            />
                            <polygon
                                points="301.06 390.2 253.86 417.4 253.86 471.9 301.06 499.1 301.06 471.9 277.46 458.3 277.46 431 301.06 417.4 324.66 431 324.66 458.2 348.26 444.6 348.26 417.4 301.06 390.2"
                                fill={design.logoBg}
                                strokeWidth="0"
                            />
                        </g>
                    </g>
                </g>
                <g id="_Text_" data-name="&amp;lt;Text&amp;gt;">
                    <text fill="#fff" fontFamily="Lab Grotesque Medium" fontSize="22">
                        <tspan textAnchor="middle" x="50%" y="80">
                            {t('program_achievement_graphic.program_achievement_graphic.congratulations')}
                        </tspan>
                    </text>

                    <text fill="#fff" fontFamily="Lab Grotesque Medium" fontSize="27">
                        <tspan textAnchor="middle" x="50%" y="114">
                            {wrappedName[0]?.toUpperCase()}
                        </tspan>
                        <tspan textAnchor="middle" x="50%" y="145">
                            {wrappedName[1]?.toUpperCase()}
                        </tspan>
                    </text>

                    <text fill="#fff" fontFamily="Lab Grotesque Medium" fontSize="34">
                        <tspan textAnchor="middle" x="50%" y={withHonors ? '190' : '230'}>
                            {t('program_achievement_graphic.program_achievement_graphic.you_have')}
                        </tspan>
                    </text>

                    <text fill="#fff" fontFamily="Lab Grotesque Bold">
                        <tspan fontSize="60" textAnchor="middle" x="50%" y={withHonors ? '260' : '303'}>
                            {t(
                                `program_achievement_graphic.program_achievement_graphic.${graduatedLocaleKey}`,
                            )?.toUpperCase()}
                        </tspan>
                        {withHonors && (
                            <tspan fontSize="42" textAnchor="middle" x="50%" y="310">
                                {t(
                                    'program_achievement_graphic.program_achievement_graphic.with_honors',
                                )?.toUpperCase()}
                            </tspan>
                        )}
                    </text>

                    <text fill="#fff" fontFamily="Lab Grotesque Medium" fontSize="26">
                        <tspan textAnchor="middle" x="50%" y={programTitleLines.length === 3 ? '630' : '646'}>
                            {t(`program_achievement_graphic.program_achievement_graphic.${awardedLocaleKey}`)}
                        </tspan>
                    </text>

                    {/* PROGRAM TITLE */}
                    {programTitleLines.length === 1 && (
                        <text fill="#fff" fontFamily="Lab Grotesque Bold" fontSize="56">
                            <tspan textAnchor="middle" x="50%" y="720">
                                {programTitleLines[0]}
                            </tspan>
                        </text>
                    )}

                    {programTitleLines.length === 2 && (
                        <text fill="#fff" fontFamily="Lab Grotesque Bold" fontSize="40">
                            <tspan textAnchor="middle" x="50%" y="700">
                                {programTitleLines[0]}
                            </tspan>
                            <tspan textAnchor="middle" x="50%" y="750">
                                {programTitleLines[1]}
                            </tspan>
                        </text>
                    )}

                    {programTitleLines.length === 3 && (
                        <text fill="#fff" fontFamily="Lab Grotesque Bold" fontSize="32">
                            <tspan textAnchor="middle" x="50%" y="680">
                                {programTitleLines[0]}
                            </tspan>
                            <tspan textAnchor="middle" x="50%" y="715">
                                {programTitleLines[1]}
                            </tspan>
                            <tspan textAnchor="middle" x="50%" y="750">
                                {programTitleLines[2]}
                            </tspan>
                        </text>
                    )}

                    <text fill="#fff" fontFamily="Lab Grotesque Medium">
                        <tspan
                            textAnchor="middle"
                            x="50%"
                            y="836"
                            fontSize={graphicConfig.institutionNameLines![0].fontSize}
                        >
                            {graphicConfig.institutionNameLines![0].text}
                        </tspan>
                        <tspan textAnchor="middle" x="50%" y="865" fontSize="22">
                            {t('program_achievement_graphic.program_achievement_graphic.class_of')?.toUpperCase()}{' '}
                            {formattedUserFacingMonthYearLong(
                                currentUser.relevantCohort!.graduationDate,
                                false,
                            ).toUpperCase()}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
