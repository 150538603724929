import { type UserSnakeCasedAttrs } from './types/UserAttrs.types';
import {
    type UserObjectForSaveBase,
    type UserObjectForSaveBaseKey,
    type UserObjectForSaveAdmin,
    type UserObjectForSaveAdminKey,
} from './types/UserObjectForSave.types';

const userObjectForSaveBaseKeys: UserObjectForSaveBaseKey[] = [
    'id',
    'name',
    'email',
    'active_playlist_locale_pack_id',
    'avatar_url',
    'career_profile',
    'english_language_proficiency_comments',
    'english_language_proficiency_documents_type',
    'fallback_program_type',
    'favorite_lesson_stream_locale_packs',
    'has_logged_in',
    'job_title',
    'password',
    'password_confirmation',
    'phone',
    'phone_extension',
    'sign_up_code',
    'student_email_addresses',
    'student_network_email',
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'zip',
    'country',
    'has_seen_welcome',
    'has_seen_offline_mode_consent_modal',
    'has_seen_ai_welcome_message',
    'has_seen_accepted',
    'has_seen_student_network',
    'has_seen_resources_tab',
    'has_seen_unofficial_transcripts_popup',
    'notify_email_newsletter',
    'pref_allow_community_notifications',
    'pref_decimal_delim',
    'pref_keyboard_shortcuts',
    'pref_locale',
    'pref_offline_mode',
    'pref_show_photos_names',
    'pref_sound_enabled',
    'li_fat_id',
];

const userObjectForSaveAdminKeys: UserObjectForSaveAdminKey[] = [
    ...userObjectForSaveBaseKeys,
    // `updated_at` is NOT used in `Api::UsersController#permitted_post_params` because we'd never
    // want to assign `updated_at` from a client-generated value. However, we do need to send it along
    // in the request because the server does a comparison between the DB `updated_at` and the incoming
    // `updated_at` from the client to make sure an admin can make an update on a User.
    // See also `Api::UsersController#raise_if_old_version.
    'updated_at',
    'english_language_proficiency_documents_approved',
    'english_language_proficiency_internal_notes',
    'transcripts_verified',
    'supplemental_enrollment_notes',
];

function buildUserObjectFromKeys<
    UserType extends UserObjectForSaveBase | UserObjectForSaveAdmin,
    KeyType extends UserObjectForSaveBaseKey | UserObjectForSaveAdminKey,
>(sourceUserObject: UserSnakeCasedAttrs, keys: KeyType[]) {
    const targetUserObject = {} as UserType;
    // Assign everything from the sourceUserObject to targetUserObject
    Object.assign(targetUserObject, sourceUserObject as unknown as UserType);
    // Delete anything from targetUserObject not in the supplied keys
    Object.keys(targetUserObject).forEach(key => {
        if (!keys.includes(key as KeyType)) {
            delete targetUserObject[key as keyof typeof targetUserObject];
        }
    });
    return targetUserObject;
}

export const getUserObjectForSave = (sourceUserObject: UserSnakeCasedAttrs, type = 'base') => {
    if (type === 'admin') {
        return buildUserObjectFromKeys<UserObjectForSaveAdmin, UserObjectForSaveAdminKey>(
            sourceUserObject,
            userObjectForSaveAdminKeys,
        );
    }

    return buildUserObjectFromKeys<UserObjectForSaveBase, UserObjectForSaveBaseKey>(
        sourceUserObject,
        userObjectForSaveBaseKeys,
    );
};
