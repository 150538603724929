import * as userAgentHelper from 'userAgentHelper';
import { logTinyEvent } from 'TinyEventLogger';
import {
    type PushNotificationSchema,
    type ActionPerformed,
    type Token,
    PushNotifications,
} from '@capacitor/push-notifications';
import { type CurrentUserIguanaObject } from 'Users';
import { setAuthHeaders } from 'ReduxHelpers';
import { handleSendbirdFCMPushNotification } from './sendbirdHelpers';

// Exported for specs
export const onRegistrationCallback = (user_id: CurrentUserIguanaObject['id'], token: Token) => {
    // NOTE: We're making pretty broad assumptions about platforms here. If we're not on
    // Android, we assume we're on iOS. We don't support push notifications on other platforms,
    // and should never get inside this code unless window.CORDOVA.capabilities.pushNotifications,
    // which will only be true on Android or iOS.
    const platform = userAgentHelper.isAndroidDevice() ? 'android' : 'ios';
    const url = `${window.ENDPOINT_ROOT}/api/users/${user_id}/push_notification_tokens.json`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...setAuthHeaders(new Headers()),
        },
        body: JSON.stringify({ platform, token: token.value }),
    });
};

// Exported for specs
export const onPushNotificationActionPerformedCallback = (
    user_id: CurrentUserIguanaObject['id'],
    notification: ActionPerformed,
) => {
    if (notification.actionId !== 'tap') return;

    const payload = notification.notification.data;

    // Not a customer.io push notification, likely from Sendbird.
    if (!payload['CIO-Delivery-ID'] || !payload['CIO-Delivery-Token']) {
        return;
    }

    logTinyEvent(
        'push-notification:opened',
        {
            user_id,
            delivery_id: payload['CIO-Delivery-ID'],
            device_id: payload['CIO-Delivery-Token'],
            timestamp: Math.floor(Date.now() / 1000),
        },
        {
            // This event is needed in LogToCustomerIoJob because we track when a user opens
            // a push notification from customer.io.
            log_to_customerio: true,
        },
    );
};

// Exported for specs
export const onPushNotificationReceivedCallback = (notification: PushNotificationSchema) => {
    if (!userAgentHelper.isAndroidDevice() || !notification.data.sendbird) return;
    const payload = JSON.parse(notification.data.sendbird);
    handleSendbirdFCMPushNotification(payload);
};

export const addListeners = (user_id: CurrentUserIguanaObject['id']) => {
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => onRegistrationCallback(user_id, token));

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) =>
        onPushNotificationActionPerformedCallback(user_id, notification),
    );

    // Method called when any push notification is received
    PushNotifications.addListener('pushNotificationReceived', notification => {
        onPushNotificationReceivedCallback(notification);
    });
};
