import { fetchBrandConfig } from 'AppBranding';
import { useCurrentUserIguanaObject } from 'FrontRoyalAngular';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DismissableMessage } from 'DismissableMessage/DismissableMessage';
import { Typography } from 'Typography';
import { useErrorLogService } from 'ErrorLogging';
import clsx from 'clsx';
import { useLockedAiAdvisorConfig } from '../useLockedAiAdvisorConfig';
import { type BotUiContext } from '../TutorBot.types';

export function WelcomeMessage({ uiContext }: { uiContext: BotUiContext }) {
    const { t } = useTranslation('back_royal');
    const { isLocked, config: lockedConfig } = useLockedAiAdvisorConfig(uiContext);
    // FIXME: swap to useCurrentUser hook, will need to create rtk user api to be able to
    // update hasSeenAiWelcomeMessage and ghostMode will need to be moved off of the user
    const currentUser = useCurrentUserIguanaObject();
    const ErrorLogService = useErrorLogService();
    const hasSeenMessage = currentUser?.has_seen_ai_welcome_message;
    const brandConfig = fetchBrandConfig(currentUser!.active_institution.branding);

    const [showModalMessage, setShowModalMessage] = useState(!hasSeenMessage);

    const handleAfterLeave = useCallback(() => {
        if (!currentUser) return;

        currentUser.has_seen_ai_welcome_message = true;
        if (!currentUser.ghostMode) currentUser.save();
    }, [currentUser]);

    // Users with the locked AI Advisor always see their welcome message modal
    if (!isLocked && hasSeenMessage) return null;

    // We expect everyone who has access to tutorbot to be in an instiution with
    // a tutorBotFaqUrl. If that's ever not the case, warn.
    if (!brandConfig.tutorBotFaqUrl) {
        ErrorLogService.notifyInProd('Missing TutorBot FAQ URL', undefined, {
            brand: brandConfig.brandNameShort,
            level: 'warning',
        });
    }

    const [welcomeMessageLocaleKey, welcomeMessageLocaleKeyNoLink] = {
        bot_page: ['chat.chat.ai_welcome_message_notice', 'chat.chat.ai_welcome_message_notice_no_link'],
        lesson_player: [
            'chat.chat.lesson_player_welcome_message_notice',
            'chat.chat.lesson_player_welcome_message_notice_no_link',
        ],
        review_previous_material: [
            'chat.chat.lesson_player_welcome_message_notice',
            'chat.chat.lesson_player_welcome_message_notice_no_link',
        ],
    }[uiContext];

    return (
        <div
            className={clsx(
                'flex',

                // This is a bit hackish, but we are making room for the "Return to Screen", which
                // only shows up in this position in mobile in the lesson player. 86px is:
                // * 40px absolute positioning above the Return to Screen Button
                // * minus 20px padding above the Chat element, which overlaps the 40px above the Return to Screen (=20px)
                // * plus the 46px height of the Return to Screen Button (=66px)
                // * plus 20px of spacing between the button and the welcome message (=86px)
                uiContext === 'lesson_player' ? 'mt-[86px] sm:mt-0' : null,
            )}
        >
            <div className="w-[95vw] max-w-[400px]">
                {isLocked && lockedConfig ? (
                    <DismissableMessage
                        show
                        disableDismiss
                        cta={
                            lockedConfig?.welcomeModalCta
                                ? {
                                      text: {
                                          headline: lockedConfig.welcomeModalCta.message.headline,
                                          subtext: lockedConfig.welcomeModalCta.message.subtext,
                                      },
                                      buttonText: lockedConfig.welcomeModalCta.buttonText,
                                      onClick: lockedConfig.welcomeModalCta.onClick,
                                  }
                                : undefined
                        }
                    >
                        <Typography variant="body4">
                            {t(lockedConfig.welcomeModalText.key, lockedConfig.welcomeModalText.params ?? {})}
                        </Typography>
                    </DismissableMessage>
                ) : (
                    <DismissableMessage
                        show={showModalMessage}
                        onDismiss={() => setShowModalMessage(false)}
                        onAfterLeave={handleAfterLeave}
                    >
                        <Typography variant="body4" className="mb-[15px]">
                            {brandConfig.tutorBotFaqUrl
                                ? parseHtml(
                                      t(welcomeMessageLocaleKey, {
                                          institutionName: brandConfig.brandNameShort,
                                          faqUrl: brandConfig.tutorBotFaqUrl,
                                      }),
                                  )
                                : t(welcomeMessageLocaleKeyNoLink, {
                                      institutionName: brandConfig.brandNameShort,
                                  })}
                        </Typography>
                        <Typography variant="body4">{t('chat.chat.ai_welcome_message_stay_tuned')}</Typography>
                    </DismissableMessage>
                )}
            </div>
        </div>
    );
}
