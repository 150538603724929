import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { useEffect, useMemo, useRef, type KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { faCommentLines } from '@fortawesome/pro-thin-svg-icons';
import { faCircleStop, faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import useIsMobileMixin from 'StudentNetwork/react/useIsMobileMixin';
import { useTheme } from 'Theme';
import { AutoGrowTextAreaField } from 'AutoGrowTextAreaField';
import { type BotClientContext, type MessageFormValues } from '../TutorBot.types';

export function MessageForm({
    onSubmit,
    onCancelClick,
    className,
    isLocked,
    maxLength = 2000,
    disableInput,
    canCancel = false,
    uiContext,
}: {
    onSubmit: (data: MessageFormValues) => void;
    onCancelClick: () => void;
    uiContext: BotClientContext['uiContext'];
    isLocked: boolean;
    className?: string;
    maxLength?: number;
    disableInput?: boolean;
    canCancel?: boolean;
}) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const { isMobile } = useIsMobileMixin();
    const { t } = useTranslation('back_royal');
    const { bgColor } = useTheme();
    const formValidationSchema = Yup.object()
        .shape({
            message: Yup.string().required().max(maxLength),
        })
        .required();
    const formFunctions = useForm<MessageFormValues>({
        resolver: yupResolver(formValidationSchema),
        defaultValues: { message: '' },
        mode: 'all',
    });

    useEffect(() => {
        if (isMobile || window.CORDOVA) return;

        if (!disableInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [disableInput, isMobile]);

    const {
        handleSubmit,
        getValues,
        setFieldValue,
        watch,
        formState: { errors },
    } = formFunctions;

    const message = watch('message');
    const hasMessage = !!message?.length;
    const messageInvalid = !!errors?.message;
    const messageTooLong = messageInvalid && errors.message!.type === 'max';

    const inputLabelLocale = useMemo(
        () =>
            isLocked
                ? 'chat.chat.available_to_students_and_alumni'
                : uiContext === 'bot_page'
                ? 'chat.chat.how_can_i_help'
                : 'chat.chat.message_ai_tutor',
        [isLocked, uiContext],
    );

    const onSubmitInner = (data: MessageFormValues) => {
        onSubmit({ ...data });
        setFieldValue('message', '');
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key !== 'Enter') return;

        e.preventDefault();
        onSubmitInner(getValues());
    };

    const inputButtonClasses = clsx(
        'm-0 block border-none bg-transparent p-0 transition-all hover:brightness-90 disabled:cursor-not-allowed disabled:opacity-70',
        {
            'quantic:text-coral valar:text-blue': ['beige', 'white', 'beige-pattern', 'demo-pattern'].includes(bgColor),
            'text-turquoise': bgColor === 'turquoise',
            'text-purple': bgColor === 'purple',
            'text-blue': bgColor === 'blue',
        },
    );

    return (
        <FormProvider {...formFunctions}>
            <div className={clsx('relative', className)}>
                <form
                    data-testid="message-form"
                    className="flex rounded-[1.5em] border border-tutorbot-border bg-white px-4 py-3 text-beige-for-text shadow-smallish"
                    onSubmit={handleSubmit(onSubmitInner)}
                >
                    <div className={clsx('grow-0 basis-6', { 'opacity-40': disableInput })}>
                        <FontAwesomeIcon className="text-xl" icon={faCommentLines} />
                    </div>
                    <div className="flex-1 self-end px-4">
                        <AutoGrowTextAreaField
                            ref={inputRef}
                            name="message"
                            aria-label={t(inputLabelLocale, { AI: 'AI' })!}
                            className="max-h-[400px] overflow-y-auto bg-transparent placeholder-[#776f65] outline-none scrollbar-hide disabled:cursor-not-allowed disabled:placeholder-gray-300"
                            placeholder={t(inputLabelLocale, { AI: 'AI' })!}
                            onKeyDown={handleKeyDown}
                            disabled={disableInput}
                        />
                    </div>
                    <div className="grow-0 basis-6">
                        {hasMessage && (
                            <button
                                data-testid="submit"
                                className={inputButtonClasses}
                                type="submit"
                                disabled={isLocked || messageInvalid || disableInput}
                            >
                                <FontAwesomeIcon
                                    className="text-xl rtl:-scale-x-100"
                                    fontStyle="solid"
                                    icon={faPaperPlaneTop}
                                />
                                <span className="sr-only">{t('chat.chat.submit_message')}</span>
                            </button>
                        )}
                        {canCancel && (
                            <button
                                data-testid="stop"
                                onClick={onCancelClick}
                                className={inputButtonClasses}
                                type="button"
                                title={t('chat.chat.cancel_response')!}
                            >
                                <FontAwesomeIcon className="text-xl" fontStyle="solid" icon={faCircleStop} />
                                <span className="sr-only">{t('chat.chat.cancel_response')}</span>
                            </button>
                        )}
                    </div>
                </form>
                <div
                    data-testid="character-count"
                    className={clsx(
                        // hidden on mobile so it doesn't overlap with "AI Advisor can make mistakes" message
                        'absolute -bottom-[26px] hidden text-xs sm:block ltr:right-5 rtl:left-5',
                        messageTooLong ? 'text-red' : 'text-beige-beyond-dark',
                    )}
                >
                    {hasMessage && (
                        <>
                            <span className="rtl:hidden">
                                {message.length} / {maxLength}
                            </span>
                            <span className="ltr:hidden">
                                {maxLength} / {message.length}
                            </span>
                        </>
                    )}
                </div>
            </div>
        </FormProvider>
    );
}
