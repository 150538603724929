import clsx from 'clsx';
import { type BaseUser, getCohort } from 'Users';
import { type TFunction } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { memo, useCallback, useState } from 'react';
import { useAppHeaderViewModel } from 'Navigation';
import { useErrorLogService } from 'ErrorLogging';
import { useInProgressExam } from '../hooks/useInProgressExam';
import {
    chatActions,
    getActiveConversationId,
    getHasChangedTopic,
    getAiMessageLoading,
    getActiveConversationMessages,
    getErroredAiMessage,
} from '../redux/chat';
import { ClearButton } from '../MessageForm/ClearButton';
import { GreetingMessage } from '../GreetingMessage';
import { Message } from '../Message/Message';
import { MESSAGE_INPUT_MAX_LENGTH } from '../constants';
import { MessageForm } from '../MessageForm/MessageForm';
import {
    type OutgoingMessage,
    type BotClientContext,
    type MessageFormValues,
    type PretendAiMessage,
} from '../TutorBot.types';
import { useConversation } from '../hooks/useConversation';
import { useScrollToBottomOfChat } from '../hooks/useScrollToBottomOfChat';
import { useLockedAiAdvisorConfig } from '../useLockedAiAdvisorConfig';
import { WelcomeMessage } from '../WelcomeMessage/WelcomeMessage';
import { createPretendAiMessage, isPretendAiMessage } from '../utils/ChatMessage';

type ErrorMessageFactory = (conversationId: string, t: TFunction) => PretendAiMessage;
const getErrorMessage: ErrorMessageFactory = (conversationId: string, t: TFunction) =>
    createPretendAiMessage({
        content: t('chat.chat.error'),
        id: 'error-message',
        conversationId,
    });

type Props = {
    currentUser: BaseUser;
    clientContext: BotClientContext;

    // See comment near the BaseMessage type about what it means to be an "initial" message
    initialMessage?: OutgoingMessage | PretendAiMessage;
    endConversationOnClose?: boolean;
    enableSetNewTopic?: boolean;
    disableGreetingMessages?: boolean;
};

function ChatComponent({
    currentUser,
    clientContext: currentClientContext,
    initialMessage: currentInitialMessage,
    endConversationOnClose = false,
    enableSetNewTopic = false,
    disableGreetingMessages = false,
}: Props): JSX.Element {
    const ErrorLogService = useErrorLogService();
    const dispatch = useDispatch();
    const hasChangedTopic = useSelector(getHasChangedTopic);
    const { t } = useTranslation('back_royal');
    const activeConversationId = useSelector(getActiveConversationId);
    const aiMessageLoading = useSelector(getAiMessageLoading);
    const chatHistory = useSelector(getActiveConversationMessages);

    const erroredAiMessage = useSelector(getErroredAiMessage);
    const AppHeaderViewModel = useAppHeaderViewModel();

    // clientContext, initialMessage, and user are only used on initialization, so we
    // don't pay attention to changes to the parameters after the component is rendered
    const [clientContext] = useState(() => currentClientContext);
    const [rawInitialMessage] = useState(() => currentInitialMessage);
    const [user] = useState(() => currentUser);
    const cohort = getCohort(currentUser);
    const { inProgressExam } = useInProgressExam();

    const { isLocked } = useLockedAiAdvisorConfig(clientContext.uiContext);

    // We never expect to be in a situation where we've gotten an initial message but the bot is locked. The lesson player,
    // which is the only place that sets an initial message, hides the bot completely if it's locked. If we ever had such a
    // situation we might need to prevent the initialMessage from being sent in such a case (which I guess is what we're already
    // doing here)
    let initialMessage: OutgoingMessage | PretendAiMessage | undefined | null = rawInitialMessage;
    if (initialMessage && isLocked) {
        ErrorLogService.notifyInProd(new Error('TutorBot: initialMessage passed when AiAdvisor is locked'), null);
        initialMessage = null;
    }

    const userName = user?.nickname || user?.name;

    const { chatWindowRef, chatMessagesContainerRef } = useScrollToBottomOfChat();

    const {
        retry,
        hasSeenInitialGreeting,
        setHasSeenInitialGreeting,
        askUserMessage,
        startNewConversation,
        cancelCurrentStreamingMessage,
    } = useConversation(clientContext, cohort?.id || null, initialMessage, endConversationOnClose);
    //---------------------------
    // Conversations
    //---------------------------

    const handleSubmit = useCallback(
        (data: MessageFormValues) => {
            if (!data?.message) return;
            askUserMessage({ content: data.message, role: 'human' });
            dispatch(chatActions.setHasChangedTopic({ hasChangedTopic: false }));
        },
        [askUserMessage, dispatch],
    );

    const handleNewTopicClick = useCallback(() => {
        if (hasChangedTopic) return;

        // Reset the scroll to the top in order to ensure
        // the scrollToBottomOfChat animates the sole GreetingMessage
        // into view when a user starts a new topic.
        if (chatWindowRef?.current) {
            chatWindowRef.current.scrollTop = 0;
        }

        startNewConversation();
        dispatch(chatActions.setHasChangedTopic({ hasChangedTopic: true }));
    }, [hasChangedTopic, startNewConversation, dispatch, chatWindowRef]);

    const handleCancelClick = useCallback(() => {
        cancelCurrentStreamingMessage('by_user');
    }, [cancelCurrentStreamingMessage]);

    const disableInput = aiMessageLoading || !!inProgressExam || isLocked;

    return (
        <>
            <div className="absolute left-0 right-0 top-5 z-20 flex flex-col items-center">
                {/* mobile-only new topic button */}
                {enableSetNewTopic && (
                    <ClearButton
                        className="mb-5 basis-auto shadow-smallish sm:hidden"
                        onClick={handleNewTopicClick}
                        isLocked={isLocked}
                    />
                )}
                <WelcomeMessage uiContext={clientContext.uiContext} />
            </div>
            <div className="flex h-full flex-col">
                <div className="grow overflow-y-hidden">
                    <div
                        ref={chatWindowRef}
                        className="h-full w-full overflow-y-auto pb-6 pt-4 scrollbar-hide "
                        data-testid="chat-window"
                    >
                        <div data-testid="chat-buffer" className="flex h-full flex-col justify-end" />

                        <div data-testid="chat-messages-container" ref={chatMessagesContainerRef}>
                            {activeConversationId && !disableGreetingMessages && (
                                <GreetingMessage
                                    name={userName}
                                    hasSeenInitialGreeting={hasSeenInitialGreeting}
                                    setHasSeenInitialGreeting={setHasSeenInitialGreeting}
                                    inProgressExam={inProgressExam}
                                    uiContext={clientContext.uiContext}
                                />
                            )}

                            {chatHistory.map(
                                (message, i) =>
                                    !message.hasError &&
                                    !message.hidden && (
                                        <Message
                                            message={message}
                                            key={message.id}
                                            // We are trying to avoid showing the fake spinner again when the
                                            // chat window is closed and re-opened. This logic works to accomplish
                                            // that if there are any messages after the pretend message, which
                                            // is good enough and keeps the logic simple.
                                            showPretendLoadingSpinner={
                                                isPretendAiMessage(message) && i === chatHistory.length - 1
                                            }
                                        />
                                    ),
                            )}

                            {erroredAiMessage && activeConversationId && (
                                <Message retryClick={retry} message={getErrorMessage(activeConversationId, t)} />
                            )}
                        </div>
                    </div>
                </div>
                <div data-testid="chat-controls" className="mb relative grow-0 pt-1">
                    <div
                        data-id="chat-input-wrapper"
                        className={clsx(
                            'relative z-10 sm:mb-12',

                            // When the mobile menu is showing, we need to add extra bottom spacing
                            // in the mobile breakpoint to pull the input box above the menu. When the mobile menu
                            // is not showing, we need a bottom margin that matches the value in
                            // show_frame_player.scss#.return-to-screen-wrapper (see comment there)
                            !AppHeaderViewModel.hideMobileMenu ? 'mb-[84px]' : 'mb-[48px]',
                        )}
                    >
                        <div className={clsx('relative flex gap-[14px]')}>
                            {/* desktop-only new topic button */}
                            {enableSetNewTopic && (
                                <ClearButton
                                    className="hidden basis-auto self-end sm:flex"
                                    onClick={handleNewTopicClick}
                                    isLocked={isLocked}
                                />
                            )}
                            <MessageForm
                                onSubmit={handleSubmit}
                                className="flex-1"
                                isLocked={isLocked}
                                maxLength={MESSAGE_INPUT_MAX_LENGTH}
                                disableInput={disableInput}
                                onCancelClick={handleCancelClick}
                                canCancel={aiMessageLoading}
                                uiContext={clientContext.uiContext}
                            />
                        </div>
                        <div
                            // This is absolutely positioned so that it doesn't interfere with the bottom margin of the input,
                            // which has to match another style (see comment in chat-input-wrapper element above)
                            className={clsx(
                                'absolute w-full pt-[10px] text-center text-[10px] text-beige-for-text',
                                disableInput && 'opacity-50',
                            )}
                        >
                            {t(
                                currentClientContext.uiContext === 'bot_page'
                                    ? 'chat.chat.ai_advisor_can_make_mistakes'
                                    : 'chat.chat.ai_tutor_can_make_mistakes',
                            )}
                        </div>
                    </div>
                    <div
                        data-testid="scroll-haze"
                        className="absolute bottom-0 left-0 z-0 h-[115%] w-full bg-[linear-gradient(to_top,_rgba(251,251,251,1)_0%,_rgba(251,251,251,.95)_80%,_transparent_100%)] sm:h-[130%]"
                    />
                </div>
            </div>
        </>
    );
}

export const Chat = memo(ChatComponent) as typeof ChatComponent;

export default Chat;
