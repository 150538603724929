import {
    useMemo,
    useState,
    useEffect,
    useRef,
    type AnchorHTMLAttributes,
    type ImgHTMLAttributes,
    type HTMLAttributes,
} from 'react';
import clsx from 'clsx';
import { sortBy } from 'lodash/fp';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import rehypeExternalLinks from 'rehype-external-links';
import 'katex/dist/katex.min.css';
import { type LinkToLessonInStream, lessonsApi } from 'Lessons';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { IconButton } from '@mui/material';
import { Replay } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { useTheme } from 'Theme';
import { useSelector } from 'react-redux';
import { getStreamingAiMessage } from 'TutorBot/redux/chat';
import { type ThemeSliceState } from 'Theme/redux/theme';
import { MessageEvaluations } from './MessageEvaluations';
import {
    isLessonFrameSourceLocation,
    isDisplayableNonLessonFrameSourceLocation,
    isLinkToLessonInStream,
    getUrlForLink,
    isHelpscoutSourceLocation,
} from '../utils/sourceLocationHelpers';
import {
    getMessageSources,
    isAiMessage,
    isHumanMessage,
    isPretendAiMessage,
    isRealAiMessage,
} from '../utils/ChatMessage';
import { addFootnotesToMessage } from '../utils/addFootnotesToMessage';

import MessageSources from './MessageSources';
import {
    type StudentMessageEvaluation,
    type ChatMessage,
    type SourceMetadata,
    type DisplayableNonLessonFrameSourceLocation,
    type SourceLink,
    type RealAiMessage,
} from '../TutorBot.types';
import { helpScoutCollectionTitle, linkStyles } from './MessageSources/shared';
import { formatMathInMessage } from '../utils/formatMathInMessage';

type Props = {
    message: ChatMessage;
    showPretendLoadingSpinner?: boolean;
    className?: string;
    onThumbClick?: (messageId: string, label: StudentMessageEvaluation['label']) => void;
    retryClick?: () => void;
};

const { useGetLinksToLessonsInStreamsQuery } = lessonsApi;

const getMessageContentClasses = (
    message: ChatMessage,
    isLoadingMessage: boolean,
    bgColor: ThemeSliceState['bgColor'],
) =>
    clsx(
        'max-w-[75%] select-text rounded-[10px] px-3.5 py-2.5 flex flex-col leading-5 sm:leading-6',
        isHumanMessage(message) && 'ml-auto text-white  rtl:ml-0 rtl:mr-auto',
        isHumanMessage(message) && {
            'bg-gradient-to-r from-[#FF4D63] to-[#FE5F5F] quantic:bg-gradient-to-r quantic:from-[#FF4D63] quantic:to-[#FE5F5F] valar:bg-gradient-to-r valar:from-blue valar:to-blue valar:bg-blue':
                ['beige', 'white', 'beige-pattern', 'demo-pattern'].includes(bgColor),
            'bg-gradient-to-r from-[#349391] to-[#29B8A0]': bgColor === 'turquoise',
            'bg-gradient-to-r from-[#8F58E0] to-[#A968E1]': bgColor === 'purple',
            'bg-gradient-to-r from-[#366CCB] to-[#507EFB]': bgColor === 'blue',
        },
        isLoadingMessage && 'py-[13px]',
        isAiMessage(message) && 'border border-tutorbot-border bg-white text-tutorbot-chat shadow-smallish',
    );

const LoadingSpinner = () => <FrontRoyalSpinner className="no-top-margin no-delay static-height" />;

const ErrorMessage = ({ message, retryClick }: { message: ChatMessage; retryClick: () => void }) => (
    <IconButton onClick={retryClick} disableRipple>
        {message.content}
        <Replay className="text-green" />
    </IconButton>
);

const LoadingStatusMessage = ({ status }: { status: RealAiMessage['status'] }) => {
    const { t } = useTranslation('back_royal');
    const statusText = t([`chat.chat.${status?.key}`, ''], status?.params ?? {}) ?? null;
    return (
        <Transition
            show={!!statusText}
            enter="transition-all duration-500 ease-out"
            enterFrom="opacity-0 -translate-x-1"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-200 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <span className="text-tutorbot-chat opacity-60">{statusText}</span>
        </Transition>
    );
};

const MarkDownLink = ({ children, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <a {...rest} className={linkStyles}>
        {children}
    </a>
);

// see comment down inside the definition of preparedMessageContent
const ImgWithStreamingCursorSupport = ({ alt, ...rest }: ImgHTMLAttributes<HTMLImageElement>) => {
    if (alt === 'StreamingCursor') {
        return (
            <span>
                &nbsp;
                <span className="-mb-[2px] inline-block h-[1em] w-[0.5em] bg-tutorbot-chat opacity-50" />
            </span>
        );
    }

    return <img {...rest} alt={alt} />;
};

const MarkDownUL = ({ children }: HTMLAttributes<HTMLElement>) => (
    <ul className="my-5 ms-5 list-disc space-y-2">{children}</ul>
);

const MarkDownOL = ({ children }: HTMLAttributes<HTMLElement>) => (
    <ol className="my-5 ms-5 list-decimal space-y-2">{children}</ol>
);

const MarkDownStrong = ({ children }: HTMLAttributes<HTMLElement>) => (
    <strong className="font-semibold">{children}</strong>
);

const MarkDownParagraphHumanMessage = ({ children }: HTMLAttributes<HTMLElement>) => (
    <p className="mb-3 last:mb-0">{children}</p>
);

// We must override the color for certain elements that match differently styled selectors in scss
const MarkDownParagraphAiMessage = ({ children }: HTMLAttributes<HTMLElement>) => (
    <p className="mb-3 text-tutorbot-chat last:mb-0">{children}</p>
);

function getLessonIdForSource({
    source,
    lessonLinksForUser,
}: {
    source: SourceMetadata;
    lessonLinksForUser: Record<string, LinkToLessonInStream>;
}) {
    // I had to use this for loop to get around typescript errors. I'm sure there's a cleaner way
    for (let i = 0; i < source.locations.length; i++) {
        const loc = source.locations[i];
        if (isLessonFrameSourceLocation(loc)) {
            const lessonId = loc.lessonId;
            if (lessonLinksForUser[lessonId]) return lessonId;
        }
    }

    return null;
}

// In the case of lesson frame locations, the link for a source is an instance of LinkToLessonInStream.
// In the case of other locations, the link is just the location itself, since it has a url property.
function getLinksFromSource(
    source: SourceMetadata,
    lessonLinksForUser: Record<string, LinkToLessonInStream>,
): (LinkToLessonInStream | DisplayableNonLessonFrameSourceLocation)[] {
    const lessonId = getLessonIdForSource({ source, lessonLinksForUser });
    const linkToLessonInStream = lessonId && lessonLinksForUser[lessonId];
    if (linkToLessonInStream) return [linkToLessonInStream];

    return source.locations.filter(loc =>
        isDisplayableNonLessonFrameSourceLocation(loc),
    ) as DisplayableNonLessonFrameSourceLocation[];
}

function getSortKeyForLink(sourceLink: SourceLink) {
    const link = sourceLink.link;
    if (isLinkToLessonInStream(link)) return `${link.streamTitle}${link.lessonIndexInStream}`;
    if (isHelpscoutSourceLocation(link)) return `${helpScoutCollectionTitle(link.collectionId)}${link.title}`;
    if (isDisplayableNonLessonFrameSourceLocation(link)) return link.title;
    return ''; // you can't actually get here
}

export function Message({ message, className, retryClick, showPretendLoadingSpinner = false }: Props) {
    const { data: lessonLinksForUser } = useGetLinksToLessonsInStreamsQuery();

    // We have some hard-coded messages that we'd like to present as if they were being
    // generated via Tutorbot - in those cases, we can use the pretendToLoadMessage property
    // here to "pretend" that we're spending time retrieving the message.
    const [isPretendLoading, setIsPretendLoading] = useState(showPretendLoadingSpinner);
    const isLoadingBotMessage = message.role === 'ai' && !message.hasError && !message.complete && !message.content;
    const { bgColor } = useTheme();
    const streamingAiMessage = useSelector(getStreamingAiMessage);
    const isStreamingAiMessage = streamingAiMessage?.id === message.id;
    const [isStreamingDelay, setIsStreamingDelay] = useState(false);
    const streamingDelayTimeoutId = useRef<NodeJS.Timeout | null>(null);
    const isLoading = isPretendLoading || isLoadingBotMessage || isStreamingDelay;

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isPretendAiMessage(message) && isPretendLoading) {
            timeoutId = setTimeout(() => {
                setIsPretendLoading(false);
            }, 750);
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [message, isPretendLoading]);

    // Show the loading spinner if it's been more than 2 seconds since the last token was added to the message
    useEffect(() => {
        setIsStreamingDelay(false);

        if (streamingDelayTimeoutId.current) {
            clearTimeout(streamingDelayTimeoutId.current);
        }

        const timeoutId: NodeJS.Timeout = setTimeout(() => {
            if (isStreamingAiMessage) {
                setIsStreamingDelay(true);
            }
        }, 2000);

        streamingDelayTimeoutId.current = timeoutId;

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isStreamingAiMessage, message.content]);

    const isFromTutorBot = message.role === 'ai';
    const isError = message.id === 'error-message' && !!retryClick;

    const shouldShowEvaluations = !isPretendAiMessage(message) && isFromTutorBot && !isLoadingBotMessage;

    const sourceLinks: SourceLink[] = useMemo(() => {
        if (!lessonLinksForUser) {
            return [];
        }

        const sources = getMessageSources(message);

        const unsortedLinks: Record<string, SourceLink> = {};
        sources.forEach((source: SourceMetadata) => {
            getLinksFromSource(source, lessonLinksForUser).forEach(link => {
                const url = getUrlForLink(link);
                if (!url) return;
                if (!unsortedLinks[url]) {
                    unsortedLinks[url] = {
                        sourceIds: [],
                        link,
                    };
                }
                if (source.id) {
                    unsortedLinks[url].sourceIds.push(source.id);
                }
            });
        });

        return sortBy((link: SourceLink) => getSortKeyForLink(link), Object.values(unsortedLinks)) as SourceLink[];
    }, [message, lessonLinksForUser]);

    const preparedMessageContent = useMemo(() => {
        if (!message.content) return null;
        const footnoteReferences = isRealAiMessage(message) ? message.footnoteReferences : {};
        let messageContent = addFootnotesToMessage(message.content, footnoteReferences, sourceLinks);
        messageContent = formatMathInMessage(messageContent);

        // In order to get the cursor to display inline, it has to be injected into the markdown. We do
        // this by hacking the handling of the image tag
        const showCursor = isStreamingAiMessage && !message.complete;
        if (showCursor) {
            messageContent += '![StreamingCursor](StreamingCursor)';
        }

        return messageContent;
    }, [message, sourceLinks, isStreamingAiMessage]);

    return (
        <div className={clsx('mb-2 flex w-full sm:mb-5', className)} data-testid="message">
            <div data-testid="message-content" className={getMessageContentClasses(message, isLoading, bgColor)}>
                {isError ? (
                    <ErrorMessage retryClick={retryClick} message={message} />
                ) : (
                    <div>
                        {!isPretendLoading && preparedMessageContent && (
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
                                rehypePlugins={[rehypeKatex, [rehypeExternalLinks, { target: '_blank' }]]}
                                components={{
                                    a: MarkDownLink,
                                    img: ImgWithStreamingCursorSupport,
                                    ol: MarkDownOL,
                                    ul: MarkDownUL,
                                    strong: MarkDownStrong,
                                    p: isHumanMessage(message)
                                        ? MarkDownParagraphHumanMessage
                                        : MarkDownParagraphAiMessage,
                                }}
                            >
                                {preparedMessageContent}
                            </ReactMarkdown>
                        )}
                    </div>
                )}

                {sourceLinks.length > 0 && <MessageSources sourceLinks={sourceLinks} />}

                {isLoading && (
                    <div
                        className={clsx(
                            'flex flex-wrap items-center justify-start gap-4 self-start',
                            message.content?.length && !isPretendLoading && 'py-3',
                        )}
                    >
                        <LoadingSpinner />
                        {isRealAiMessage(message) && <LoadingStatusMessage status={message.status} />}
                    </div>
                )}
            </div>
            {shouldShowEvaluations && <MessageEvaluations messageId={message.id} />}
        </div>
    );
}
