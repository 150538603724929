import { PushNotifications, type PermissionStatus } from '@capacitor/push-notifications';
import { type CurrentUserIguanaObject } from 'Users';
import { addListeners } from './addListeners';

export const tryToRegisterDeviceForRemoteNotifications = async (user_id: CurrentUserIguanaObject['id']) => {
    if (!window.CORDOVA?.capabilities?.pushNotifications) {
        // Noop
        return;
    }

    // Request permission to receive push notifications.
    // On Android 12 and below it doesn't prompt for permission because you can always receive push notifications.
    // On iOS, the first time you use the function, it will prompt the user for push notification permission and
    // return granted or denied based on the user selection. On following calls it will get the current status of
    // the permission without prompting again.
    PushNotifications.requestPermissions()
        .then((result: PermissionStatus) => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNs/FCM
                PushNotifications.register();
                // Add listeners for registration event, notification opened event, etc.
                addListeners(user_id);
            }
        })
        .catch(() => {
            // Noop, we have seen devices / environments that do not support notifications get around the
            // window.CORDOVA?.capabilities?.pushNotifications check above. For example, Apple Mail on certain
            // macs with Apple silicon can run the iOS app natively.
        });
};
